<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Welcome to Motorpool Web Application System 🚀</v-card-title>
      <v-card-text>
        Hello {{ userData.full_name }}, Have a good day! 😉
      </v-card-text>
    </v-card>
    <job-orders-card
      class="mb-6"
    >
    </job-orders-card>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <notes-card class="mb-6">
        </notes-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <pending-appointments-card></pending-appointments-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import JobOrdersCard from './dashboard/JobOrdersCard.vue'
import NotesCard from './dashboard/NotesCard.vue'
import PendingAppointmentsCard from './dashboard/PendingAppointmentsCard.vue'

export default {
  components: {
    JobOrdersCard,
    NotesCard,
    PendingAppointmentsCard,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))

    return {
      userData,
    }
  },
}
</script>
