<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Pending Appointments</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-list class="pt-0 mt-n1">
        <v-list-item
          v-for="(data,index) in statisticsData"
          :key="index"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
        >
          <!-- <v-list-item-avatar size="38">
            <v-img :src="data.avatar"></v-img>
          </v-list-item-avatar> -->

          <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
            <div>
              <v-list-item-title class="text-sm font-weight-medium">
                {{ limit(data.booked_by, 60) }}
              </v-list-item-title>
              <div class="d-flex align-center">
                <v-icon
                  size="14"
                  class="me-1"
                >
                  {{ icons.mdiCalendarBlankOutline }}
                </v-icon>
                <v-list-item-subtitle class="text-xs">
                  {{ data.booked_date }} | {{ data.booked_time }}
                </v-list-item-subtitle>
              </div>
            </div>

            <v-spacer></v-spacer>

            <v-chip
              small
              color="warning"
              :class="`v-chip-light-bg warning--text font-weight-semibold mt-1`"
            >
              {{ data.plate_number }}
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const statisticsData = ref([])

    const getStatistics = () => {
      axios.get('/appointments/statistics')
        .then(r => {
          statisticsData.value = r.data
        })
    }

    const limit = (string = '', len = 0) => {
      if (string.length <= len) {
        return string
      }

      return `${string.substring(0, len)}.. .`
    }

    onMounted(() => {
      getStatistics()
    })

    return {
      statisticsData,
      getStatistics,
      limit,

      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
