<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Job Orders Card</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Job orders</span>
      <span>😎 up to date.</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="2"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="data.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icons.mdiClipboardFileOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.count }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiClipboardFileOutline, mdiDotsVertical } from '@mdi/js'
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const statisticsData = ref([])

    const getStatistics = () => {
      axios.get('/job-orders/statistics')
        .then(r => {
          statisticsData.value = r.data
        })
    }

    onMounted(() => {
      getStatistics()
    })

    return {
      statisticsData,
      getStatistics,

      // icons
      icons: {
        mdiDotsVertical,
        mdiClipboardFileOutline,
      },
    }
  },
}
</script>
